var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:(_vm.smUp
      ? 'padding: 15px; margin-left: -5%;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 15px;opacity: 1;'
      : ''),attrs:{"flat":!_vm.smUp,"rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"_id"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [(item.type == 'contact')?_c('span',[_vm._v(" Contacto ")]):(item.type == 'transport')?_c('span',[_vm._v(" Transporte ")]):(item.type == 'recyclable')?_c('span',[_vm._v(" Reciclaje ")]):_c('span',[_vm._v(" Confinamiento ")])]}},{key:"item.bSeen",fn:function(ref){
      var item = ref.item;
return [(!item.bSeen)?_c('span',{staticStyle:{"color":"#782336"}},[_vm._v("No leído")]):_c('span',{staticStyle:{"color":"#782336","font-size":"16px"}},[_vm._v("Leido")])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(!item.bSeen)?_c('v-btn',{attrs:{"rounded":"","color":"#EAEAEA"},on:{"click":function($event){return _vm.read(item._id)}}},[_vm._v("No leído")]):_c('v-btn',{attrs:{"rounded":"","dark":"","color":"#782336"},on:{"click":function($event){return _vm.read(item._id)}}},[_vm._v("Leido")]),_vm._v(" "+_vm._s(item.comment)+" ")],1)]}}])}),_c('v-dialog',{attrs:{"id":"form_view","overlay-opacity":".97","persistent":"","max-width":"40%"},model:{value:(_vm.bActive),callback:function ($$v) {_vm.bActive=$$v},expression:"bActive"}},[_c('v-card',{staticClass:"dialog_card_container",attrs:{"shaped":_vm.smUp}},[_c('v-layout',{attrs:{"row":"","justify-end":""}},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"icon":"","fab":"","small":"","color":"grey","text":""},on:{"click":function($event){_vm.bActive = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',{staticStyle:{"margin-top":"-30px"}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('p',{staticClass:"dialog_title_text",domProps:{"textContent":_vm._s(_vm.dialog_title)}})])],1),_c('v-container',{staticStyle:{"margin-top":"-30px"}},[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('p',{staticClass:"dialog_description_text",domProps:{"textContent":_vm._s(_vm.dialog_desc)}})])],1),_c('v-container',[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-layout',{staticStyle:{"max-width":"80%"},attrs:{"row":""}},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.bActive = false}}},[_vm._v(_vm._s(_vm.cancel_btn_txt))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dense":""},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.submit_btn_txt))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }