<template>
  <v-card
    :flat="!smUp"
    rounded
    :style="
      smUp
        ? 'padding: 15px; margin-left: -5%;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 15px;opacity: 1;'
        : ''
    "
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      @click:row="clicked"
    >
      <template v-slot:item.type="{ item }">
        <span v-if="item.type == 'contact'"> Contacto </span>
        <span v-else-if="item.type == 'transport'"> Transporte </span>
        <span v-else-if="item.type == 'recyclable'"> Reciclaje </span>
        <span v-else> Confinamiento </span>
      </template>
      <template v-slot:item.bSeen="{ item }">
        <span v-if="!item.bSeen" style="color: #782336">No leído</span>
        <span style="color: #782336;font-size:16px;" v-else>Leido</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-btn
            rounded
            color="#EAEAEA"
            v-if="!item.bSeen"
            @click="read(item._id)"
            >No leído</v-btn
          >
          <v-btn rounded dark color="#782336" v-else @click="read(item._id)"
            >Leido</v-btn
          >
          {{ item.comment }}
        </td>
      </template>
    </v-data-table>

    <v-dialog
      id="form_view"
      overlay-opacity=".97"
      persistent
      max-width="40%"
      v-model="bActive"
    >
      <v-card :shaped="smUp" class="dialog_card_container">
        <!-- Start close icon -->
        <v-layout row justify-end>
          <v-btn
            style="margin-right: 10px"
            icon
            fab
            small
            color="grey"
            text
            @click="bActive = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <!-- End close icon -->
        <!-- Start dialog title -->
        <v-container style="margin-top: -30px">
          <v-layout row justify-center>
            <p v-text="dialog_title" class="dialog_title_text" />
          </v-layout>
        </v-container>
        <!-- End dialog title -->
        <!-- Start description text -->
        <v-container style="margin-top: -30px">
          <v-layout row justify-center>
            <p v-text="dialog_desc" class="dialog_description_text" />
          </v-layout>
        </v-container>
        <!-- End description text -->
        <v-container>
          <!-- Center button layout -->
          <v-layout row justify-center>
            <!-- Limit button layout extension -->
            <v-layout row style="max-width: 80%">
              <v-btn color="red" text @click="bActive = false">{{
                cancel_btn_txt
              }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" dense @click="confirmDelete">{{
                submit_btn_txt
              }}</v-btn>
            </v-layout>
            <!-- Limit button layout extension -->
          </v-layout>
          <!-- Center button layout  -->
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    statusOptions: [
      { name: "No leído", id: 0 },
      { name: "Leído", id: 1 },
    ],
    expand: false,

    expanded: [],
    singleExpand: true,
    bActive: false,
    submit_btn_txt: "Aceptar",
    cancel_btn_txt: "Cancelar",
    dialog_title: "Eliminar categoría",
    itemToDelete: "",
    dialog_desc:
      "Estás a punto de eliminar un mensaje ¿Estás seguro de realizar esta acción?",

    statusOptions: [
      { name: "Carrito", id: 0 },
      { name: "Pagado", id: 1 },
      { name: "Enviado", id: 2 },
      { name: "Entregado", id: 3 },
    ],
    client_to_edit: {},
    client_to_delete: null,
    b_edit_client: false,
    b_delete_client: false,
    delete_icon: "mdi-delete",
    edit_icon: "mdi-pencil",
    headers: [
      { text: "Nombre", value: "name", align: "left" },
      { text: "Correo electrónico", value: "email", align: "left" },
      { text: "Teléfono", value: "phone", align: "left" },
      { text: "Estado", value: "state", align: "left" },
      { text: "Tipo", value: "type", align: "left" },
      { text: "Estatus", value: "bSeen", width: "250px" },
    ],
  }),
  filters: {
    toCurrency(value) {
      value = Number(value);
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    formatDate(date) {
      /*var d = date.split(/\D+/g).map(function(v) {
        return parseInt(v, 10);
      });
      let newDate =  new Date(d[0], d[1] - 1, d[2], d[3], d[4], d[5]);*/
      date = new Date(date);
      date.setHours(24);
      let monthArray = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sept",
        "Oct",
        "Nov",
        "Dic",
      ];
      let day = date.getDate();
      if (day < 10) day = "0" + day;
      let month = date.getMonth();
      let year = date.getFullYear();
      return `${day}/${monthArray[month]}/${year}`;
    },
  },
  methods: {
    clicked(value) {
      if (this.expanded.length == 0) {
        this.expanded.push(value);
        if (!value.bSeen) {
        }
      } else {
        if (this.expanded[0]._id == value._id) {
          this.expanded.pop();
        } else {
          this.expanded.pop();
          this.expanded.push(value);
        }
      }
    },
    read(client) {
      let that = this;

      var config = {
        method: "patch",
        url: `${uri}/api/v1/serviceContacts/${client}`,
        headers: {
          Authorization: this.usr_token,
        },
      };

      db(config)
        .then(function (response) {
          const alert = {
            color: "success",
            text: response.data.message,
          };
          that.$store.commit("toggle_alert", alert);

          that.update();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    confirmDelete() {
      let that = this;

      var axios = require("axios");

      var config = {
        method: "patch",
        url: `${uri}api/contacts/${this.itemToDelete}`,
        headers: {
          Authorization: this.usr_token,
        },
      };

      axios(config)
        .then(function (response) {
          that.$emit("update");
          that.bActive = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    delete_client(client) {
      console.log("hola");
      this.itemToDelete = client._id;
      this.bActive = true;
    },
    update() {
      this.$emit("update");
    },
    edit_client(client) {
      this.client_to_edit = client;
      this.b_edit_client = true;
    },
    setSelected(value, id) {
      let that = this;

      var data = JSON.stringify({ iStatus: value });

      var config = {
        method: "put",
        url: `https://api.crua.mx/orders/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: this.usr_token,
        },
        data: data,
      };

      db(config)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
          that.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
        })
        .catch(function (err) {
          console.log(err);
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });

      console.log(value);
      //  trigger a mutation, or dispatch an action
    },
  },
  components: {},
  props: {
    items: Array,
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
};
</script>
<style scoped>
.attribute_flex {
  margin-top: 18px;
}
hr {
  height: 0.5px;
  color: rgba(140, 140, 140, 0.112);
  background-color: rgba(114, 114, 114, 0.112);
  border-color: transparent;
}
.attribute_name {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
}
.attribute_value {
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
}
.application_table_row {
  cursor: pointer;
}
.zero-class .v-icon,
.one-class .v-icon,
.two-class .v-icon,
.three-class .v-icon {
  color: white;
}
.zero-class .v-select__selection.v-select__selection--comma,
.one-class .v-select__selection.v-select__selection--comma,
.two-class .v-select__selection.v-select__selection--comma,
.three-class .v-select__selection.v-select__selection--comma {
  color: white !important;
}
.zero-class .v-input__control .v-input__slot {
  color: white;
  background-color: #ff9d00 !important;
  font-weight: bold;
}
.one-class .v-input__control .v-input__slot {
  color: white;
  background-color: #00b7e3 !important;
  font-weight: bold;
}
.two-class .v-input__control .v-input__slot {
  color: white;
  background-color: #ab86de !important;
  font-weight: bold;
}
.three-class .v-input__control .v-input__slot {
  color: white;
  background-color: #4daf7c !important;
  font-weight: bold;
}
.text_content {
  margin-top: 15px;
  color: #000000;
  font-size: 12px;
  font-family: "Open sans", sans-serif;
}
</style>
<style>
.zero-class .v-icon,
.one-class .v-icon,
.two-class .v-icon,
.three-class .v-icon {
  color: white;
}
.zero-class .v-select__selection.v-select__selection--comma,
.one-class .v-select__selection.v-select__selection--comma,
.two-class .v-select__selection.v-select__selection--comma,
.three-class .v-select__selection.v-select__selection--comma {
  color: white !important;
  font-weight: 100;
  font-size: 12px;
}
.zero-class .v-input__control .v-input__slot {
  color: white;
  background-color: #ff9d00 !important;
  font-weight: bold;
}
.one-class .v-input__control .v-input__slot {
  color: white;
  background-color: #00b7e3 !important;
  font-weight: bold;
}
.two-class .v-input__control .v-input__slot {
  color: white;
  background-color: #ab86de !important;
  font-weight: bold;
}
.three-class .v-input__control .v-input__slot {
  color: white;
  background-color: #4daf7c !important;
  font-weight: bold;
}
</style>